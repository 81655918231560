<template>
  <div class="contact">
    <template v-if="!data.submittedSuccessfully">
      <Form
        id="contact_form"
        method="post"
        action="/contact#contact_form"
        accept-charset="UTF-8"
        class="contact__form"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <input type="hidden" name="form_type" value="contact" />
        <input type="hidden" name="utf8" value="✓" />

        <div class="contact__category">
          <div class="contact__fieldset__wrap">
            <div class="contact__fieldset">
              <div class="contact__field contact__field--half">
                <v-text-input
                  :value="data.form.firstName"
                  :disabled="data.disabled"
                  name="contact[first_name]"
                  class="box"
                  id="first-name"
                  type="text"
                  label="First Name"
                  :required="true"
                />
              </div>
              <div class="contact__field contact__field--half">
                <v-text-input
                  :value="data.form.lastName"
                  :disabled="data.disabled"
                  name="contact[last_name]"
                  class="box"
                  id="last-name"
                  type="text"
                  label="Last Name"
                  :required="true"
                />
              </div>
              <div class="contact__field contact__field--half">
                <v-text-input
                  :value="data.form.organisation"
                  :disabled="data.disabled"
                  name="contact[organisation]"
                  class="box"
                  id="organisation"
                  type="text"
                  label="Organisation"
                  :required="true"
                />
              </div>
              <div class="contact__field contact__field--half">
                <v-text-input
                  :value="data.form.accountNumber"
                  :disabled="data.disabled"
                  name="contact[account_number]"
                  class="box"
                  id="accountNumber"
                  type="text"
                  label="Account Number"
                />
              </div>
              <div class="contact__field contact__field--half">
                <v-text-input
                  :value="data.form.emailAddress"
                  :disabled="data.disabled"
                  id="email-address"
                  class="box"
                  type="email"
                  name="contact[email]"
                  label="Email Address"
                  :required="true"
                />
              </div>
              <div class="contact__field contact__field--half">
                <v-text-input
                  :value="data.form.contactNumber"
                  :disabled="data.disabled"
                  id="contact-number"
                  class="box"
                  type="text"
                  name="contact[contact_number]"
                  label="Contact Number"
                  :required="true"
                />
              </div>
              <div class="contact__field contact__field--full">
                <v-select
                  :value="data.form.subjectType"
                  :disabled="data.disabled"
                  :options="props.subject_options"
                  class="box"
                  id="subject"
                  name="contact[subject_type]"
                  label="Subject"
                  :required="true"
                />
              </div>
              <div class="contact__field contact__field--full">
                <v-text-area
                  :value="data.form.message"
                  :disabled="data.disabled"
                  name="contact[message]"
                  class="box"
                  id="message"
                  type="text"
                  label="Message"
                  :rows="3"
                  :required="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="contact__submit">
          <button
            :disabled="data.disabled"
            class="btn__base btn__base--dark"
            type="submit"
          >
            Submit
          </button>
        </div>
      </Form>
    </template>
    <template v-else>
      <div class="contact__success">
        <p class="accent" v-html="props.settings.success_message">
        </p>
      </div>
    </template>
  </div>
</template>

<script setup>
import { reactive } from 'vue';
import * as Yup from 'yup';
import { Form, Field, ErrorMessage } from 'vee-validate';
import VTextInput from './VTextInput.vue';
import VTextArea from './VTextArea.vue';
import VSelect from './VSelect.vue';

const props = defineProps({
  settings: {
    type: Object,
    required: true,
    default: () => {},
  },
  subject_options: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const data = reactive({
  disabled: false,
  submittedSuccessfully: false,
  formAction: '',
  form: {
    firstName: '',
    lastName: '',
    organisation: '',
    accountNumber: '',
    emailAddress: '',
    contactNumber: '',
    subjectType: '',
    message: '',
  },
});

const schema = Yup.object().shape({
  'contact[first_name]': Yup.string().required('First Name is required.'),
  'contact[last_name]': Yup.string().required('Last Name is required.'),
  'contact[organisation]': Yup.string().required('Organisation is required.'),
  'contact[email]': Yup.string()
    .email('Email Address must be valid')
    .required('Email Address is required.'),
  'contact[contact_number]': Yup.string()
    .matches(/^[0-9,]+$/, 'Contact Number must be numeric.')
    .required('Contact Number is required.'),
  'contact[subject_type]': Yup.string().required('Subject is required.'),
  'contact[message]': Yup.string().required('Message is required.'),
});

const onInvalidSubmit = () => {
  data.disabled = true;
  setTimeout(() => {
    data.disabled = false;
  }, 3000);
};

if (window.location.href.includes('contact_posted=true')) {
  data.submittedSuccessfully = !data.submittedSuccessfully;
}
</script>
